import FeatureGates from '@atlaskit/feature-gate-js-client';

import type { CohortValues } from '../experiment-config';
import { CASF_COHORTS } from '../experiment-config';

export const useExperimentEnrollment = (experimentName: string) => {
	const cohort = FeatureGates.getExperimentValue<CohortValues>(
		experimentName,
		'cohort',
		CASF_COHORTS.NOT_ENROLLED,
		{ fireExperimentExposure: false },
	);

	return {
		cohort,
	};
};
