import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition';

import { CASF_COHORTS } from '../experiment-config';

import { useExperimentEnrollment } from './useExperimentEnrollment';

export const useQuickSummaryABExperiment = () => {
	const { edition } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { cohort } = useExperimentEnrollment('confluence_quick_summary');

	try {
		const isEnabled = FeatureGates.checkGate('confluence_quick_summary_fg_ab');

		const isEligible =
			isEnabled && (edition === ConfluenceEdition.FREE || edition === ConfluenceEdition.STANDARD);

		const isQualified =
			isEligible && (cohort === CASF_COHORTS.CONTROL || cohort === CASF_COHORTS.TREATMENT);
		const isVariation = cohort === CASF_COHORTS.TREATMENT;

		return {
			isQualified,
			isVariation,
		};
	} catch (error) {
		createAnalyticsEvent({
			type: 'sendOperationalEvent',
			data: {
				action: 'error',
				actionSubject: 'useQuickSummaryExperiment',
				source: 'useQuickSummaryExperimentHook',
				attributes: {
					error: error.message,
					edition,
				},
			},
		}).fire();

		return error;
	}
};
