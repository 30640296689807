import React, { type ReactNode } from 'react';

import { styled } from '@compiled/react';

import { reduceMotionAsPerUserPreference, useIsReducedMotion } from '@atlaskit/motion';
import { Box, xcss } from '@atlaskit/primitives';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GradientPropertyContainer = styled.div({
	width: '100%',
	position: 'relative',
	'@property --gradient-angle': {
		syntax: `"<angle>"`,
		initialValue: '270deg',
		inherits: 'false',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'@keyframes rotationAnimation': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'0%': {
			'--gradient-angle': '0deg',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'100%': {
			'--gradient-angle': '360deg',
		},
	},
	maxHeight: 'inherit',
});

const outerContainerStyles = xcss({
	overflow: 'hidden',
	position: 'relative',
	borderRadius: 'border.radius.100',
	background: `conic-gradient(
    from var(--gradient-angle, 45deg),
    #BF63F3,
    #FFA900 25%,
    #0469FF 67%,
    #0065FF,
    #BF63F3
  )`,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	...reduceMotionAsPerUserPreference,
	maxHeight: 'inherit',
});

const animationStyles = xcss({
	animation: 'rotationAnimation linear 2s infinite',
});

const glowStyles = xcss({
	position: 'absolute',
	filter: 'blur(25px)',
	opacity: '0.5',
	height: '100%',
	width: '100%',
});

const innerContainerStyles = xcss({
	borderRadius: 'border.radius',
	backgroundColor: 'elevation.surface.overlay',
	maxHeight: 'inherit',
	display: 'flex',
	flexDirection: 'column',
	backgroundClip: 'content-box',
});

export type AIBorderProps = {
	loading?: boolean;
	children?: ReactNode;
};

export const AIBorder = ({ loading = false, children, ...props }: AIBorderProps) => {
	const isReducedMotion = useIsReducedMotion();
	const shouldRotate = loading && !isReducedMotion;
	const containerStyles = shouldRotate
		? [outerContainerStyles, animationStyles]
		: [outerContainerStyles];

	return (
		<GradientPropertyContainer data-testid="ai-border-container" {...props}>
			{shouldRotate && (
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
				<Box xcss={[...containerStyles, glowStyles]} testId="loading-glow" />
			)}
			{/* eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage */}
			<Box xcss={containerStyles}>
				<Box padding="space.025" xcss={innerContainerStyles}>
					{children}
				</Box>
			</Box>
		</GradientPropertyContainer>
	);
};
