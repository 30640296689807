import { useCallback, useContext } from 'react';

import FeatureGates from '@atlaskit/feature-gate-js-client';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ConfluenceEdition, useSessionData } from '@confluence/session-data';
import { SPAViewContext } from '@confluence/spa-view-context';
import { useExperimentEnrollment } from './useExperimentEnrollment';
import { CASF_COHORTS } from '../experiment-config';

export const useFireQuickSummaryExposureEvent = ({
	skip,
	experimentName,
}: {
	skip: boolean;
	experimentName: string;
}) => {
	const { edition } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isSiteAdmin } = useContext(SPAViewContext);
	const { cohort } = useExperimentEnrollment(experimentName);

	const fireQuickSummaryExposureEvent = useCallback(() => {
		if (
			!edition ||
			skip ||
			edition === ConfluenceEdition.PREMIUM ||
			cohort === CASF_COHORTS.NOT_ENROLLED
		) {
			return;
		}

		void FeatureGates.manuallyLogExperimentExposure(experimentName);

		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'exposed',
				actionSubject: 'experiment',
				actionSubjectId: `${experimentName}-exposed`,
				attributes: {
					edition,
					cohort,
					isSiteAdmin,
				},
				source: experimentName,
			},
		}).fire();
	}, [cohort, createAnalyticsEvent, edition, experimentName, isSiteAdmin, skip]);

	return { fireQuickSummaryExposureEvent };
};
